import { Box } from '@mui/material';
import { TFunction } from 'i18next';
import { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useGetFrontPageFeature, usePutFeature } from 'src/apis/featureAPI';
import { useUpdateMilestoneDays } from 'src/apis/settingsAPI';
import { TextField, ToastifyAlert } from 'src/components/mui-components';
import { useIsPsaProduct } from 'src/utils/siteInfo';
import { SettingsContentWrapper } from 'src/components/layout/SettingsContentWrapper';
import { SwitchSection } from '../../components';
import { useFeatures } from '../../components/hook/useFeaturesHook';

interface IExtra {
  checkedFeatures: Record<string, any>;
  featureValues: Record<string, any>;
  milestoneDaysOnBlur: (value: string) => void;
  setFeatureValues: (value: SetStateAction<Record<string, any>>) => void;
  tSysAdminFrontPage: TFunction<'systemAdminFrontPage', undefined>;
}

const Extra = ({
  checkedFeatures,
  featureValues,
  milestoneDaysOnBlur,
  setFeatureValues,
  tSysAdminFrontPage,
}: IExtra) => (
  <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
    {tSysAdminFrontPage('MilestonesMessage')}
    <TextField
      hiddenLabel
      ariaLabel="Milestone Days"
      data-automation-id="MilestonesDaysInput"
      id="Milestones"
      value={featureValues?.ShowMileStones !== undefined ? featureValues.ShowMileStones : ''}
      variant="outlined"
      size="small"
      disabled={!checkedFeatures?.ShowMileStones}
      onChange={(e) => {
        if (!Number.isNaN(Number(e.target.value))) {
          setFeatureValues({ ...featureValues, ShowMileStones: e.target.value });
        } else {
          setFeatureValues({
            ...featureValues,
            ShowMileStones: featureValues?.ShowMileStones,
          });
        }
      }}
      onBlur={(e) => milestoneDaysOnBlur(e.target.value)}
      sx={{ width: 50, ml: 1, mr: 1 }}
    />
    {tSysAdminFrontPage('MilestonesDays')}
  </Box>
);

export const FrontPage = () => {
  const { data } = useGetFrontPageFeature();
  const { mutate: updateFeature } = usePutFeature();
  const { mutate: updateMilestoneDays } = useUpdateMilestoneDays();
  const isPsaProduct = useIsPsaProduct();

  const { t: tSysAdminFrontPage } = useTranslation('systemAdminFrontPage');
  const { t: tWidget } = useTranslation('reportingWidgets');
  const { t: tFrontPage } = useTranslation('frontpage');
  const { t: tNotification } = useTranslation('notificationCenter');

  const { checkedFeatures, setCheckedFeatures, featureValues, setFeatureValues } =
    useFeatures(data);

  const toggleCheckbox = async (name: string, isChecked: boolean) => {
    const feature = data.find((feat: { name: string }) => feat.name === name);

    if (!feature) return;

    const response = await updateFeature(
      {
        featureId: feature.identifier,
        featureEnable: isChecked,
      },
      {
        onSuccess: () => {
          const status = isChecked
            ? tSysAdminFrontPage('FeatureEnabled')
            : tSysAdminFrontPage('FeatureDisabled');
          toast.success(
            <ToastifyAlert
              description={tSysAdminFrontPage('FeatureUpdateSuccessMessage', {
                STATUS: status,
              })}
            />,
            {
              autoClose: 2000,
              closeButton: false,
            },
          );
        },
      },
    );

    if (response !== undefined && response !== null) {
      setCheckedFeatures((prev) => ({ ...prev, [name]: isChecked }));
    }
  };

  const milestoneDaysOnBlur = (value: string) => {
    updateMilestoneDays(value, {
      onSuccess: () => {
        toast.success(
          <ToastifyAlert description={tSysAdminFrontPage('MilestonesUpdateSuccessMessage')} />,
          {
            autoClose: 2000,
            closeButton: false,
          },
        );
      },
    });
  };

  const sections = [
    {
      title: tSysAdminFrontPage('InfoName'),
      items: [
        {
          name: 'FrontPageInternalMessage',
          label: tFrontPage('InternalInformationHeading'),
          tooltipTitle: tFrontPage('InternalInformationHeading'),
          tooltipDesc: tFrontPage('InternalInformationText'),
        },
      ],
      isVisible: isPsaProduct,
    },
    {
      title: tSysAdminFrontPage('NotificationName'),
      items: [
        {
          name: 'DesignSevenNotificationCenter',
          label: tNotification('Heading'),
          tooltipTitle: tNotification('InfoModalHeading'),
          tooltipDesc: tNotification('InfoModalText'),
        },
      ],
      isVisible: isPsaProduct,
    },
    {
      title: tSysAdminFrontPage('MilestonesName'),
      items: [
        {
          name: 'ShowMileStones',
          label: tSysAdminFrontPage('MilestonesInInfoboxName'),
          tooltipTitle: tSysAdminFrontPage('MilestonesName'),
          tooltipDesc: tSysAdminFrontPage('MilestonesText'),
          extra: (
            <Extra
              checkedFeatures={checkedFeatures}
              featureValues={featureValues}
              milestoneDaysOnBlur={milestoneDaysOnBlur}
              setFeatureValues={setFeatureValues}
              tSysAdminFrontPage={tSysAdminFrontPage}
            />
          ),
        },
      ],
      isVisible: true,
    },
    {
      title: tSysAdminFrontPage('WidgetsName'),
      items: [
        {
          name: 'WidgetInvoicedPerMonth',
          label: tWidget('InvoicedPerMonth'),
          tooltipTitle: tWidget('InvoicedPerMonth'),
          tooltipDesc: tWidget('InvoicedPerMonthWidgetDescription'),
        },
        {
          name: 'WidgetAverageHourlyRate',
          label: tWidget('AverageHourlyRate'),
          tooltipTitle: tWidget('AverageHourlyRate'),
          tooltipDesc: tWidget('AverageHourlyRateWidgetDescription'),
        },
        {
          name: 'WidgetPlannedAbsence',
          label: tWidget('PlannedAbsence'),
          tooltipTitle: tWidget('PlannedAbsence'),
          tooltipDesc: tWidget('PlannedAbsenceWidgetDescription'),
        },
        {
          name: 'WidgetProjectEconomyHealth',
          label: tWidget('ProjectEconomyHealth'),
          tooltipTitle: tWidget('ProjectEconomyHealth'),
          tooltipDesc: tWidget('ProjectEconomyHealthWidgetDescription'),
        },
        {
          name: 'WidgetTimeTrackingDelay',
          label: tWidget('TimeTrackingDelay'),
          tooltipTitle: tWidget('TimeTrackingDelay'),
          tooltipDesc: tWidget('TimeTrackingDelayWidgetDescription'),
        },
        {
          name: 'WidgetVacationCalculation',
          label: tWidget('VacationCalculation'),
          tooltipTitle: tWidget('VacationCalculation'),
          tooltipDesc: tWidget('VacationCalculationWidgetDescription'),
        },
      ],
      isVisible: true,
    },
  ];

  return (
    <SettingsContentWrapper
      header={tSysAdminFrontPage('Heading')}
      description={tSysAdminFrontPage('Description')}
    >
      {sections.map((section) => {
        // Check if all feature names in the section are in checkedFeatures
        const allFeaturesExist = section.items.every((item) => item.name in checkedFeatures);
        return (
          allFeaturesExist &&
          section.isVisible && (
            <SwitchSection
              key={section.title}
              title={section.title}
              items={section.items}
              checkedItems={checkedFeatures}
              onToggle={toggleCheckbox}
              translation="systemAdminFrontPage"
            />
          )
        );
      })}
    </SettingsContentWrapper>
  );
};
