import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/mui-components';

interface SwitchSectionProps {
  title: string;
  items: {
    name: string;
    label: string;
    extra?: JSX.Element;
    tooltipTitle?: string;
    tooltipDesc?: string | JSX.Element;
  }[];
  checkedItems: Record<string, boolean>;
  onToggle: (name: string, isChecked: boolean) => void;
  translation: string;
}

export const SwitchSection: React.FC<SwitchSectionProps> = ({
  title,
  items,
  checkedItems,
  onToggle,
  translation,
}) => {
  const [openItem, setOpenItem] = useState<string | null>(null);
  const { t } = useTranslation(translation);

  return (
    <FormControl component="fieldset" data-automation-id={`SwitchSection${title}`}>
      <FormLabel component="legend" focused={false}>
        <strong>{title}</strong>
      </FormLabel>
      <FormGroup>
        {items.map((item) => (
          <Box key={item.name}>
            <FormControlLabel
              key={item.name}
              control={
                <Switch
                  checked={!!checkedItems[item.name]}
                  onChange={(event) => onToggle(item.name, event.target.checked)}
                  data-automation-id={`Switch${item.name}`}
                />
              }
              label={
                <>
                  {item.label}
                  {item.extra ?? null}
                  {item.tooltipTitle && (
                    <Box sx={{ display: 'inline-flex', verticalAlign: 'middle' }}>
                      <IconButton
                        title={t('InfoIconTooltipTitle')}
                        data-automation-id={`${item.name}ModalTriggerButton`}
                        onClick={(event) => {
                          event.preventDefault();
                          setOpenItem(item.name);
                        }}
                      >
                        <InfoOutlined fontSize="small" />
                      </IconButton>
                    </Box>
                  )}
                </>
              }
            />
            <Dialog open={openItem === item.name} onClose={() => setOpenItem(null)}>
              <DialogTitle id={`InfoDialog-Title-${title}`}>{item.tooltipTitle}</DialogTitle>
              <DialogContent>
                <DialogContentText id={`InfoDialog-Description-${title}`}>
                  {item.tooltipDesc}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenItem(null)}
                  data-automation-id={`${title}InfoModalCloseButton`}
                >
                  <Typography>{t('ModalCloseButton')}</Typography>
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        ))}
      </FormGroup>
    </FormControl>
  );
};
