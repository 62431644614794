import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { usePostCompetenceGroups, useUpdateCompetenceGroups } from 'src/apis/competenceGroupAPI';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ToastifyAlert,
} from 'src/components/mui-components';
import { useInitialFocus } from 'src/hooks/useInitialFocus';
import { z } from 'zod';

import { zodResolver } from '@hookform/resolvers/zod';

import { DialogProps } from '../types';

export const NewCompetenceCategoryDialog = ({
  closeDialog,
  setRowInfo,
  rowInfo,
  isEditing,
  setIsEditing,
  ...rest
}: DialogProps) => {
  const { t } = useTranslation('systemAdministrationEmployeesCompetences');

  // Initial focus
  const initialFocusElement = useRef<HTMLInputElement | null>(null);
  useInitialFocus(initialFocusElement);

  // Form handling
  const formSchema = z.object({
    categoryName: z.string().min(1),
  });

  const form = useForm({
    defaultValues: {
      categoryName: rowInfo?.name || '',
    },
    resolver: zodResolver(formSchema),
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = form;

  // Data
  const { mutate: postCompetenceGroup } = usePostCompetenceGroups();
  const { mutate: updateCompetenceGroup } = useUpdateCompetenceGroups();

  // Actions
  const cleanUp = (closeOnSubmit: boolean = true) => {
    if (closeOnSubmit) {
      closeDialog();
    } else {
      initialFocusElement.current?.focus();
    }
    reset();
    if (setRowInfo) setRowInfo({});
    if (setIsEditing) setIsEditing(false);
  };

  const successCreateToast = (categoryName: string) => {
    toast(
      <ToastifyAlert
        title={t('modals.CategoryCreatedToastTitle', {
          CATEGORY_NAME: categoryName,
        })}
        description={t('modals.CategoryCreatedToastDescription')}
        color="success"
        data-automation-id="CreateCompetenceCategoryDialogToastSuccess"
      />,
      { autoClose: 4000, closeButton: false },
    );
  };

  const errorCreateToast = (categoryName: string, error: string) => {
    toast(
      <ToastifyAlert
        title={`${categoryName} was not created`}
        description={`${error}`}
        color="error"
        data-automation-id="CreateCompetenceCategoryDialogToastError"
      />,
      { closeButton: true },
    );
  };

  const handleCreate = () => {
    postCompetenceGroup(
      {
        name: form.getValues('categoryName'),
      },
      {
        onSuccess: () => {
          successCreateToast(form.getValues('categoryName'));
          cleanUp();
        },
        onError: (error) => {
          errorCreateToast(form.getValues('categoryName'), `${error}`);
        },
      },
    );
  };

  const handleCreateAndNew = () => {
    postCompetenceGroup(
      {
        name: form.getValues('categoryName'),
      },
      {
        onSuccess: () => {
          successCreateToast(form.getValues('categoryName'));
          cleanUp(false);
        },
        onError: (error) => {
          errorCreateToast(form.getValues('categoryName'), `${error}`);
        },
      },
    );
  };

  const handleEdit = () => {
    updateCompetenceGroup(
      {
        id: rowInfo?.apiId,
        name: form.getValues('categoryName'),
      },
      {
        onSuccess: () => {
          toast(
            <ToastifyAlert
              title={t('modals.CategoryEditedToastTitle', {
                CATEGORY_NAME: form.getValues('categoryName'),
              })}
              description={t('modals.CategoryEditedToastDescription')}
              color="success"
              data-automation-id="EditCompetenceCategoryDialogToastSuccess"
            />,
            { autoClose: 4000, closeButton: false },
          );
          cleanUp();
        },
        onError: (error) => {
          toast(
            <ToastifyAlert
              title={t('modals.CategoryEditToastTitleError', {
                CATEGORY_NAME: form.getValues('categoryName'),
              })}
              description={`${error}`}
              color="error"
              data-automation-id="EditCompetenceCategoryDialogToastError"
            />,
            { closeButton: true },
          );
        },
      },
    );
  };

  const handleCancel = () => {
    cleanUp();
  };

  return (
    <Dialog
      onClose={handleCancel}
      fullWidth
      maxWidth="sm"
      data-automation-id={`${isEditing ? 'Edit' : 'Create'}CompetenceCategoryDialog`}
      {...rest}
    >
      <form onSubmit={handleSubmit(isEditing ? handleEdit : handleCreate)}>
        <DialogTitle>
          {isEditing ? t('modals.CategoryEditHeader') : t('modals.CategoryCreateHeader')}
        </DialogTitle>
        <DialogContent>
          {/* Add the box to make room for the floating label */}
          <Box sx={{ pt: 1 }}>
            <Controller
              name="categoryName"
              control={control}
              render={({ field }) => (
                <TextField
                  data-automation-id={`${isEditing ? 'Edit' : 'Create'}CompetenceCategoryInputName`}
                  label={t('modals.CategoryCreateInputNameLabel')}
                  variant="outlined"
                  ariaLabel={t('modals.CategoryCreateInputNameLabel')}
                  required
                  fullWidth
                  inputRef={initialFocusElement}
                  {...field}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCancel}
            data-automation-id={`${
              isEditing ? 'Edit' : 'Create'
            }CompetenceCategoryDialogButtonCancel`}
          >
            {t('modals.CreateButtonCancelText')}
          </Button>
          {!isEditing ? (
            <Button
              variant="contained"
              type="button"
              onClick={handleCreateAndNew}
              disabled={!isDirty || !isValid}
              data-automation-id="CompetenceCategoryDialogButtonSaveAndNew"
            >
              {t('modals.CreateButtonSaveAndNewText')}
            </Button>
          ) : null}
          <Button
            variant="contained"
            type="submit"
            disabled={!isDirty || !isValid}
            data-automation-id={`${
              isEditing ? 'Edit' : 'Create'
            }CompetenceCategoryDialogButtonSave`}
          >
            {t('modals.CreateButtonSaveText')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
