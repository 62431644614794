import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useGetResourceManagementFeature, usePutFeature } from 'src/apis/featureAPI';
import { ToastifyAlert } from 'src/components/mui-components';
import { SettingsContentWrapper } from 'src/components/layout/SettingsContentWrapper';
import { SwitchSection } from '../../components';
import { useFeatures } from '../../components/hook/useFeaturesHook';

export const ResourceManagement = () => {
  const { data } = useGetResourceManagementFeature();
  const { mutate: updateFeature } = usePutFeature();

  const { t } = useTranslation('systemAdminResourceManagement');

  const { checkedFeatures, setCheckedFeatures } = useFeatures(data);

  const toggleFeatures = async (name: string, isChecked: boolean) => {
    const feature = data.find((feat: { name: string }) => feat.name === name);

    if (!feature) return;

    const response = await updateFeature(
      {
        featureId: feature.identifier,
        featureEnable: isChecked,
      },
      {
        onSuccess: () => {
          const status = isChecked ? t('FeatureEnabledToastText') : t('FeatureDisabledToastText');
          toast.success(
            <ToastifyAlert
              description={t('FeatureUpdateSuccessMessage', {
                STATUS: status,
              })}
            />,
            {
              autoClose: 2000,
              closeButton: false,
            },
          );
        },
      },
    );

    if (response !== undefined && response !== null) {
      setCheckedFeatures((prev) => ({ ...prev, [name]: isChecked }));
    }
  };

  const sections = [
    {
      title: t('ResourcePlannerSettingsName'),
      items: [
        {
          name: 'Competences',
          label: t('EmployeeCompetencesText'),
          tooltipTitle: t('EmployeeCompetencesText'),
          tooltipDesc: (
            <Trans
              i18nKey="CompetencesTooltipText"
              key="CompetencesTooltipText"
              ns="systemAdminResourceManagement"
              defaults={t('CompetencesTooltipText')}
              components={[
                <a
                  key={0}
                  href="/Settings/Role/RolesOverview"
                  style={{ textDecoration: 'underline' }}
                >
                  <span key={0} style={{ textDecoration: 'underline' }} />
                </a>,
                <strong key={1} />,
              ]}
            />
          ),
        },
      ],
    },
  ];

  return (
    <SettingsContentWrapper header={t('Heading')} description={t('Description')}>
      {sections.map((section) => {
        // Check if all feature names in the section are in checkedFeatures
        const allFeaturesExist = section.items.every((item) => item.name in checkedFeatures);
        return (
          allFeaturesExist && (
            <SwitchSection
              key={section.title}
              title={section.title}
              items={section.items}
              checkedItems={checkedFeatures}
              onToggle={toggleFeatures}
              translation="systemAdminResourceManagement"
            />
          )
        );
      })}
    </SettingsContentWrapper>
  );
};
