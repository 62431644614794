import { useMutation, useQuery } from '@tanstack/react-query';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { IResourcePlannerItem, IResourcePlannerResult } from 'src/apis/types/resourcePlannerAPI';
import { IFilterStoreProps } from 'src/reducers/FilterReducer';
import { getExpandedRowOriginalIds } from 'src/screens/ResourcePlanner/helper/expandedRow';
import { RPProjectViewInitialExpandedRowsStateKey } from 'src/screens/ResourcePlanner/localStorageKeys';
import { postFetch } from 'src/utils/fetcher';
import { concatQuerys } from 'src/utils/url';
import {
  GROUP_BY_PROJECT_KEY,
  GROUP_BY_PROJECT_MSW_STATUS_KEY,
} from './getGroupByProjectAPI.constants';

const GROUP_BY_PROJECT_URL_PATH = '/api/v2/resource-planner/partial-group-by-work-item-first-load';

const resourcePlannerFetch = (
  filters: string,
  params: { [key: string]: string },
  expandedRowIds?: string,
): Promise<IResourcePlannerResult> =>
  postFetch({
    path: `${GROUP_BY_PROJECT_URL_PATH}${concatQuerys(
      Object.keys(params).map((key) => ({
        param: key.replaceAll('-', ''),
        value:
          // we need the hyphens in dates
          key === 'period-starts-at' || key === 'period-ends-at'
            ? params[key]
            : params[key].replaceAll('-', ''),
      })),
    )}`.concat(filters),
    key: GROUP_BY_PROJECT_MSW_STATUS_KEY,
    body: JSON.stringify({ expandedIds: expandedRowIds }),
  });

const PARTIAL_GROUP_BY_PROJECT_URL_PATH = '/api/v2/resource-planner/partial-group-by-work-item';
export const PARTIAL_GROUP_BY_PROJECT_MSW_STATUS_KEY = 'GetPartialGroupByProjectAPI';

const partialResourcePlannerFetch = (
  filters: string,
  params: { [key: string]: string },
  rowId: string,
): Promise<IResourcePlannerResult> =>
  postFetch({
    path: `${PARTIAL_GROUP_BY_PROJECT_URL_PATH}${concatQuerys(
      Object.keys(params).map((key) => ({
        param: key.replaceAll('-', ''),
        value:
          // we need the hyphens in dates
          key === 'period-starts-at' || key === 'period-ends-at'
            ? params[key]
            : params[key].replaceAll('-', ''),
      })),
    )}`.concat(filters),
    key: PARTIAL_GROUP_BY_PROJECT_MSW_STATUS_KEY,
    body: JSON.stringify({ expandedIds: rowId }),
  });

export const getTranslatedStatus = (
  result: IResourcePlannerItem[],
  t: TFunction<'resourcePlanner', undefined>,
): IResourcePlannerItem[] =>
  result.map(({ status, children, ...child }) => ({
    status,
    translatedStatus: t(status),
    children: children ? getTranslatedStatus(children, t) : undefined,
    ...child,
  }));

export const getAllResourceIds = (result: IResourcePlannerItem[]): string[] =>
  result.map(({ resourceId }) => resourceId);

export const useGetGroupByProject = (
  { selectedFilterList }: Pick<IFilterStoreProps, 'selectedFilterList'>,
  params?: { [key: string]: string },
  rpLoading?: boolean,
) => {
  const { t } = useTranslation('resourcePlanner');
  const items = Object.keys(selectedFilterList);
  const filtersParam = `${concatQuerys(
    items
      .filter(
        (item) => selectedFilterList[item].values.map((value) => value.value).toString() !== '',
      )
      .map((item) => ({
        param: `${item}Ids`,
        value: selectedFilterList[item].values.map((value) => value.value).toString(),
        isAppended: true,
      })),
  )}${concatQuerys(
    items
      .filter(
        (item) => selectedFilterList[item].values.map((value) => value.value).toString() !== '',
      )
      .map((item) => ({
        param: `Is${item}Inclusive`,
        value: selectedFilterList[item].isInclude ? 'true' : 'false',
        isAppended: true,
      })),
  )}`;
  const {
    data: {
      model: {
        properties: { periods = [], children = [], viewOptions = {}, responseType = '' } = {},
      } = {},
    } = {},
    isError,
    ...restProps
  } = useQuery(
    [GROUP_BY_PROJECT_KEY, params ?? {}, selectedFilterList ?? {}],
    async () => {
      const expandedRowIds = getExpandedRowOriginalIds(RPProjectViewInitialExpandedRowsStateKey);

      const response = await resourcePlannerFetch(filtersParam, params ?? {}, expandedRowIds);

      if (response?.model?.properties?.children) {
        response.model.properties.children = getTranslatedStatus(
          response.model.properties.children,
          t,
        );
      }

      return response;
    },
    {
      // any previous data will be kept when fetching new data because the query key changed.
      enabled: !rpLoading,
      keepPreviousData: true,
      staleTime: Infinity,
    },
  );

  return {
    periods,
    children,
    isEmpty: children.length <= 0,
    isFilterHasValue: Object.keys(selectedFilterList).length !== 0,
    isError,
    responseType,
    viewOptions,
    ...restProps,
  };
};
export const useGetPartialGroupByProject = (
  { selectedFilterList }: Pick<IFilterStoreProps, 'selectedFilterList'>,
  params?: { [key: string]: string },
) => {
  const { t } = useTranslation('resourcePlanner');
  const items = Object.keys(selectedFilterList);
  const filtersParam = `${concatQuerys(
    items
      .filter(
        (item) => selectedFilterList[item].values.map((value) => value.value).toString() !== '',
      )
      .map((item) => ({
        param: `${item}Ids`,
        value: selectedFilterList[item].values.map((value) => value.value).toString(),
        isAppended: true,
      })),
  )}${concatQuerys(
    items
      .filter(
        (item) => selectedFilterList[item].values.map((value) => value.value).toString() !== '',
      )
      .map((item) => ({
        param: `Is${item}Inclusive`,
        value: selectedFilterList[item].isInclude ? 'true' : 'false',
        isAppended: true,
      })),
  )}`;

  return useMutation({
    mutationFn: async (rowId: string) => {
      const response = await partialResourcePlannerFetch(filtersParam, params ?? {}, rowId);

      if (response?.model?.properties?.children) {
        response.model.properties.children = getTranslatedStatus(
          response.model.properties.children,
          t,
        );
      }

      return response;
    },
  });
};
