import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  GridRowHeightParams,
  GridRowModes,
  GridRowModesModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useGetUnallocatedTasks } from 'src/apis/resourcePlannerAPI';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { DataGrid } from 'src/components/mui-components';
import { formatNumber } from 'src/utils/number';
import {
  EColumnName,
  EditableEndDate,
  EditableHours,
  EditableLazyLoadHourlyRate,
  EditableStartDate,
} from '../EditableComponents';
import { NoRowsOverlay } from '../EmptyStates/NoRowsOverlay';
import { ETaskTableField, ITaskTableRow } from './TaskTable.types';

type TTaskTableHandle = {
  getFormIsValid: () => boolean;
  getFormValues: () => ITaskTableRow[];
};

interface ITaskTable {
  endDate: Date;
  projectId: string;
  setHoursList: Dispatch<SetStateAction<Record<string, string>>>;
  startDate: Date;
  userId?: string;
}

export const TaskTable = forwardRef<TTaskTableHandle, ITaskTable>(
  ({ endDate, projectId, setHoursList, startDate, userId }, ref) => {
    const { t } = useTranslation('assignFlow');
    const apiRef = useGridApiRef();
    const siteLocale = useGetLocale();

    const { data, isFetching } = useGetUnallocatedTasks({
      projectId,
      userId,
    });

    const [taskList, setTaskList] = useState<ITaskTableRow[]>([]);
    const taskCurrency = taskList?.[0]?.hourlyRateCurrencyAbb;
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>();

    const colDef = useRef<Partial<GridColDef>>({
      sortable: false,
    });

    const getSelectedRows = useCallback(() => {
      if (!apiRef.current?.getSelectedRows?.()?.size) {
        return [];
      }

      const selectedRowIds = Array.from(apiRef.current.getSelectedRows().keys());

      return selectedRowIds.reduce(
        (a, b) => [...a, apiRef.current.getRowWithUpdatedValues(b, '') as ITaskTableRow],
        [] as ITaskTableRow[],
      );
    }, [apiRef]);

    const updateHoursList = useCallback(() => {
      setHoursList(() =>
        getSelectedRows().reduce(
          (a, b) => ({
            ...a,
            [b.workItemSourceReferenceId]: b.hoursInt,
          }),
          {},
        ),
      );
    }, [getSelectedRows, setHoursList]);

    const columns: GridColDef[] = useMemo(
      () => [
        {
          ...colDef.current,
          field: ETaskTableField.wbsNo,
          renderHeader: (params) => (
            <abbr title={t('TaskTable.WBSLong')}>{params.colDef.headerName}</abbr>
          ),
          headerName: t('TaskTable.WBS'),
          width: 90,
        },
        {
          ...colDef.current,
          field: ETaskTableField.name,
          flex: 1,
          headerName: t('TaskTable.Name'),
          valueGetter: ({ row }: { row: ITaskTableRow }) => `${row.name} (${row.no})`,
          minWidth: 250,
        },
        {
          ...colDef.current,
          field: ETaskTableField.startDate,
          headerName: t('TaskTable.StartDate'),
          valueFormatter: ({ value }) => new Date(value).toLocaleDateString(),
          width: 100,
        },
        {
          ...colDef.current,
          field: ETaskTableField.endDate,
          headerName: t('TaskTable.EndDate'),
          valueFormatter: ({ value }) => new Date(value).toLocaleDateString(),
          width: 100,
        },
        {
          ...colDef.current,
          align: 'right',
          field: ETaskTableField.budgetString,
          headerAlign: 'right',
          headerName: t('TaskTable.Budget'),
          valueFormatter: ({ value }) => formatNumber(value, siteLocale),
          width: 80,
        },
        {
          ...colDef.current,
          align: 'right',
          editable: true,
          field: ETaskTableField.hours,
          headerAlign: 'right',
          headerName: t('TaskTable.Hours'),
          renderEditCell: (params) => (
            <EditableHours {...params} name={params.row.name} onChange={updateHoursList} />
          ),
          width: 85,
        },
        {
          ...colDef.current,
          editable: true,
          field: ETaskTableField.allocationStartDate,
          headerName: t('TaskTable.AllocationStartDate'),
          renderEditCell: (params) => (
            <EditableStartDate
              {...params}
              ariaLabel={t('EditableComponents.AllocatedStartDateLabel', { name: params.row.name })}
              dataAutomationId="AllocatedStartDate"
              maxDate={endDate}
              minDate={startDate}
              textFieldId={`AllocatedStartDatePicker${params.row.id}`}
            />
          ),
          type: 'date',
          width: 160,
        },
        {
          ...colDef.current,
          editable: true,
          field: ETaskTableField.allocationEndDate,
          headerName: t('TaskTable.AllocationEndDate'),
          renderEditCell: (params) => (
            <EditableEndDate
              {...params}
              ariaLabel={t('EditableComponents.AllocatedEndDateLabel', { name: params.row.name })}
              colName={EColumnName.ALLOCATION_START_DATE}
              dataAutomationId="AllocatedEndDate"
              maxDate={endDate}
              textFieldId={`AllocatedEndDatePicker${params.row.id}`}
            />
          ),
          type: 'date',
          width: 160,
        },
        {
          ...colDef.current,
          editable: true,
          field: ETaskTableField.hourlyRate,
          headerName: t('TaskTable.HourlyRate', { currency: taskCurrency ?? 'DKK' }),
          renderEditCell: (params) => <EditableLazyLoadHourlyRate {...params} />,
          type: 'singleSelect',
          width: 185,
        },
      ],
      [endDate, siteLocale, startDate, t, taskCurrency, updateHoursList],
    );

    const getFormIsValid = () => {
      const selectedRowIds = Array.from(apiRef.current.getSelectedRows().keys());

      return selectedRowIds.reduce((a, b) => {
        if (apiRef.current.getCellValue(b, ETaskTableField.error)) {
          return false;
        }

        return a;
      }, true);
    };

    useImperativeHandle(ref, () => ({
      getFormIsValid,
      getFormValues: getSelectedRows,
    }));

    useEffect(() => {
      setTaskList(
        (data ?? []).map(({ properties: p }) => ({
          ...p,
          allocationEndDate: endDate,
          allocationStartDate: startDate ?? new Date(),
          budgetString: Number(p.budgetHour).toFixed(2),
          error: '',
          hourlyRate: '',
          hours: '',
          hoursInt: 0,
          id: p.workItemSourceReferenceId,
          name: p.name,
        })),
      );
    }, [data, endDate, startDate]);

    useEffect(() => {
      if (!taskList.length) {
        return;
      }

      const rowModes = taskList.reduce(
        (a, b) => ({
          ...a,
          [b.workItemSourceReferenceId]: { mode: GridRowModes.Edit },
        }),
        {},
      );

      setRowModesModel(rowModes);
    }, [taskList]);

    return (
      <DataGrid
        apiRef={apiRef}
        checkboxSelection
        columns={columns}
        data-automation-id="TaskTable"
        disableColumnMenu
        disableCellOutline
        disableColumnReorder
        disableColumnResize
        disableColumnTopBorder
        disableRowSelectionOnClick
        disableVirtualization
        editMode="row"
        rowHeight={56}
        hideFooter
        initialState={{
          pinnedColumns: {
            left: [
              GRID_CHECKBOX_SELECTION_COL_DEF.field,
              ETaskTableField.wbsNo,
              ETaskTableField.name,
            ],
          },
        }}
        loading={isFetching}
        onRowSelectionModelChange={updateHoursList}
        rowModesModel={rowModesModel}
        rows={taskList}
        slots={{
          noRowsOverlay: () => NoRowsOverlay({ message: t('TaskTable.NoResults') }),
        }}
        sx={{
          '.MuiDataGrid-row--editing': {
            boxShadow: 'none',
            '.MuiDataGrid-cell': {
              backgroundColor: 'transparent',
            },
          },
        }}
      />
    );
  },
);
